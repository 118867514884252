/*
* Theme Name: Miron
* Description: Miron Theme for corporate and creative sites, responsive and clean layout.
* Author: design_mylife
* Version: 1.3
* preview url: http://designmylife.co.in/miron/index.html
*/

/*************google font******************/
@import url(https://fonts.googleapis.com/css?family=Poppins:400,700,500,600,300);

/* --------------------------------------------------------	*/
/*-----------------Base -----------------------------------     */
/* --------------------------------------------------------	*/
body {
    font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
    font-weight: 400;
    background: #fff;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    cursor: default;
    font-size: 15px;
    color: #555;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin-top: 0px;
    margin-bottom: 20px;
    font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
}

h1 {
    font-weight: 600;
    font-size: 36px;
    margin-bottom: 0.50em;
    color: #292b2c;
}

h2.subtitle {
    font-weight: 300;
    color: #555;
    font-size: 19px;
    margin-bottom: 0px;
}

h2.subtitle i {
    color: #555;
    font-size: 2em;
}

h3 {
    text-transform: uppercase;
}

a {
    text-decoration: none !important;
}

a.hover-color {
    -webkit-transition: all 200ms ease-in;
    -o-transition: all 200ms ease-in;
    -moz-transition: all 200ms ease-in;
    transition: all 200ms ease-in;
}

a.hover-color:hover {
    color: #F54325 !important;
}

iframe,
fieldset {
    border: 0;
    margin: 0;
    padding: 0;
}

b,
strong {
    font-weight: 400;
}

p {
    line-height: 26px;
}

::selection {
    background: #D0D0D0;
    /* Safari */
    color: black;
}

::-moz-selection {
    background: #D0D0D0;
    /* Firefox */
    color: black;
    outline: none;
}

/*============================================================*/
/*========= margin padding for giving spaces in rows =========*/
/*============================================================*/
.margin-btm20 {
    margin-bottom: 20px;
}

.padding-60 {
    padding: 60px 0px;
}

.margin-btm40 {
    margin-bottom: 40px;
}

.divied-40 {
    width: 100%;
    height: 40px;
    clear: both;
}

.divied-60 {
    width: 100%;
    height: 60px;
    clear: both;
}

/*============================================================*/
/*========= bootstrap overrides ==============================*/
/*============================================================*/



/*pagination*/
.pagination li a {
    position: relative;
    float: left;
    padding: 6px 12px;
    line-height: 1.42857;
    text-decoration: none;
    color: #000;
    background-color: transparent;
    border: 1px solid #eee;
    margin-left: -1px;
    -webkit-transition: all 200ms ease-in;
    -o-transition: all 200ms ease-in;
    -moz-transition: all 200ms ease-in;
    transition: all 200ms ease-in;
}

.pagination li a:hover {
    color: #fff;
    background-color: #F54325;
    border-color: #F54325;
}

/*progress bar*/
.progress {
    height: 15px;
    line-height: 10px;
    box-shadow: none;
    -webkit-box-shadow: none;
    border-radius: 0px;
    -webkit-border-radius: 0px;
    background: #E9E9E9;
}

.progress-bar {
    line-height: 10px;
    background: #F54325;
    box-shadow: none;
    -webkit-box-shadow: none;
}

h3.heading-progress {
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 5px;
}

h3.heading-progress span {
    color: #F54325;
    font-weight: 400;
}

/*tabs*/

.tabs .nav-tabs {
    border-bottom: none;
}

.tabs .nav-tabs li {
    margin-bottom: 0;
}

@media (max-width: 767px) {
    .tabs .nav-tabs li {
        width: 100%;
        float: none;
        margin-bottom: 2px;
    }

    .tabs .nav-tabs li a {
        margin-right: 0 !important;
    }

    .tabs .nav-tabs li.active a {
        border-bottom: 2px solid #dcdcdc !important;
    }
}

.tabs .nav-tabs li a {
    background: #f7f7f7;
    margin-right: 2px;
    border-left: 1px solid #f7f7f7;
    border-right: 1px solid #f7f7f7;
    border-bottom: none !important;
    border-radius: 0;
    padding: 12px 20px;
    color: #6d6d6d;
    position: relative;
}

.tabs .nav-tabs li a:after {
    content: "";
    display: block;
    position: absolute;
    top: -1px;
    left: -1px;
    right: -1px;
    height: 3px;
}

.tabs .nav-tabs li a:before {
    content: "";
    display: block;
    position: absolute;
    bottom: -1px;
    left: 0;
    right: 0;
    height: 1px;
}

.tabs .nav-tabs li a:hover {
    border-color: #f7f7f7;
}

.tabs .nav-tabs>li>a .fa {
    margin-right: 4px;
}

.tabs .nav-tabs li.active a {
    color: #F54325;
    border-left-color: #eee;
    border-right-color: #eee;
    background: #fff;
}

.tabs .nav-tabs li.active a:after {
    background: #F54325;
}

.tabs .nav-tabs li.active a:before {
    background: #fff;
}

.tabs .nav-tabs li.active a:hover {
    border-bottom-color: #fff;
}

.tabs .tab-content {
    padding: 15px 8px;
    border: 1px solid #eee;
    background: #fff;

}

.tab-content p {
    overflow: hidden;
}

.tabs .tab-content:before,
.tabs .tab-content:after {
    content: " ";
    display: table;
}

.tabs .tab-content:after {
    clear: both;
}

.tabs .tab-content p:last-child {
    margin-bottom: 0;
}

/*resposive audio*/
.audio {
    width: 100%;
}

/*resposive video*/
.responsive-video {
    width: 100%;
    position: relative;
    padding: 0;
    padding-top: 56.25%;
}

.responsive-video iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}


/*============================*/
/*========= main menu ========*/
/*============================*/
.navbar-inverse {
    background-color: #232323;

}

.navbar {
    border: none !important;
    border-radius: 0;
    margin-bottom: 0px;
}


.navbar-inverse .navbar-nav>.active>a,
.navbar-inverse .navbar-nav>.active>a:hover,
.navbar-inverse .navbar-nav>.active>a:focus {
    background-color: #F54325 !important;
    color: #fff;
}

.navbar-inverse .navbar-nav>.open>a,
.navbar-inverse .navbar-nav>.open>a:hover,
.navbar-inverse .navbar-nav>.open>a:focus {
    background-color: transparent;
    color: #fff;
}

.navbar-nav>li>a {
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-size: 13px;
}

/*============================*/
/*======dropdowm menu=========*/
/*============================*/
.navbar .dropdown-menu {
    padding: 0;
    margin: 0;
    min-width: 190px;
    background-color: #222;
    border: none;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    border-top: 2px solid #F54325 !important;
}

.navbar .dropdown-menu li a {
    color: #fff;
    padding: 9px 20px;
    border-bottom: 1px solid #333;
    font-weight: 400;
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
    -webkit-transition: all 200ms ease-in;
    -o-transition: all 200ms ease-in;
    -moz-transition: all 200ms ease-in;
    transition: all 200ms ease-in;
}

.navbar .dropdown-menu li:last-child a {
    border-bottom: none;
}

.navbar .dropdown-menu li a:hover {
    background: #F54325;
    color: #fff;
}

@media (min-width: 768px) {
    .navbar-nav>li>a {

        padding: 15px 15px;

        line-height: 44px;
        vertical-align: middle;
    }

    .navbar-brand {
        color: #fff !important;
        float: left;
        padding: 5px 5px;
        font-weight: 700;
        font-size: 35px;
        text-transform: uppercase;

    }

    .navbar-brand img {
        margin-top: 3px;
        height: 60px;
        margin-bottom: 3px;
    }
}


/*============================*/
/*========= main menu option 2 with white background =========*/
/*============================*/
.navbar-default {
    background-color: #fff;
    box-shadow: 0px 0px 8px #999;
}

.navbar-default .navbar-nav>.active>a,
.navbar-default .navbar-nav>.active>a:hover,
.navbar-default .navbar-nav>.active>a:focus {
    color: #fff;
    background-color: #F54325;
}

.navbar-default .navbar-nav>.open>a,
.navbar-default .navbar-nav>.open>a:hover,
.navbar-default .navbar-nav>.open>a:focus {
    background-color: #F54325;
    color: #fff;
}

.navbar-default .navbar-nav>li>a {
    color: #777;
}

/*==================== ========*/
/*========= main flex slider =========*/
/*============================*/
#main-slider-bg {
    background-image: url('/images/optumsoft-Banner-3.png');
    position: relative;
    height: 241px;
    background-size: cover;
    -webkit-background-size: cover;
}

.slider-overlay {
    background: rgba(0, 0, 0, .4);
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

.slider-main {
    padding-top: 100px;
}

.slider-main .flex-control-paging li a {
    background: #fff !important;
}

.slider-main .flex-control-paging li a.flex-active {
    background: #F54325 !important;
}

.slider-main .flex-control-nav {
    width: 100%;
    position: absolute;
    text-align: center;
    bottom: 0px !important;
    position: relative;
    margin-top: 80px;
}

.slider-heading {
    color: #fff;
    font-weight: 300;
    font-size: 4em;
    margin-top: 30px;
    margin-bottom: 30px;
}

.slider-desc {
    color: #f0f0f0;
    margin-bottom: 30px;
    font-weight: 400;
    font-size: 14px;
}


/*==================== ========*/
/*========= responsive css for 768px that work in all divices =========*/
/*============================*/

@media(max-width:767px) {

    /*for slider text*/
    .slider-heading {
        font-size: 2.9em;
        margin-top: 10px;
    }

    .navbar-brand {
        padding: 5px 5px;
    }

    .navbar-brand img {
        margin-top: 0px;
        height: 45px;
        margin-bottom: 3px;
    }

    #page-tree {
        padding-top: 70px !important;
    }

}



/*==================== ========*/
/*========= buttons css =========*/
/*============================*/
.btn {
    font-weight: 400;
    font-size: 13px;
    text-shadow: none;
    text-transform: uppercase;
    -webkit-transition: all 200ms ease-in;
    -o-transition: all 200ms ease-in;
    -moz-transition: all 200ms ease-in;
    transition: all 200ms ease-in;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    display: inline-block;
    letter-spacing: 0px;
}

/*button white for colored background*/
.btn-white {
    background-color: transparent;
    color: #fff;
    border-radius: 0;
    border: 1px solid #fff;
}

.btn-white:hover {
    background-color: #fff;
    color: #222;
}

.btn-theme {
    background-color: transparent;
    color: #009cca;
    border-radius: 0;
    border: 1px solid #009cca;
    font-weight: bold;
    font-size: 18px;
    max-width: 220px;
    margin: auto;
}

.btn-theme:hover {
    background-color: #009cca;
    border-color: #009cca;
    color: #fff;
}

.btn-dark {
    background-color: #009cca;
    color: #fff;
    font-weight: bold;
    font-size: 18px;
    border: 1px solid #009cca;
    max-width: 220px;
    margin: auto;
}

.btn-dark:hover:not([disabled]) {
    background-color: transparent;
    border-color: #009cca;
    color: #009cca;
}

.btn-lg {
    padding: 14px 28px;
}

/*============================*/
/*========= Social icons =========*/
.social li a i {
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    background-color: #fff;
    border-radius: 2px;
    -webkit-border-radius: 2px;
    text-align: center;
    margin-bottom: 9px;
    font-size: 19px;
    color: #222;
    -webkit-transition: all 200ms ease-in;
    -o-transition: all 200ms ease-in;
    -moz-transition: all 200ms ease-in;
    transition: all 200ms ease-in;
}

.social li a i:hover {
    color: #fff;
    background-color: #007395;
}

/*============================*/
/*============================*/
/*========= Services =========*/
/*============================*/

.services-box {
    padding-top: 25px;
    padding-bottom: 15px;
    padding-left: 12px;
    padding-right: 12px;
    background: #fff;
    text-align: center;

}

.services-box h3 {
    margin-bottom: 10px;
    margin-top: 15px;
    font-size: 19px;
}

.services-box i {
    font-size: 39px;
    color: #F54325;
}

.services-box p {
    margin-bottom: 0px;
}

/*services option 2*/
.services-box-2 {
    text-align: center;
}

.services-box-2 i {
    font-size: 34px;
}

.services-box-2 h3 {
    margin: 13px 0px;
    font-size: 20px;
}

.list-services li i {
    margin-right: 10px;
    font-size: 18px;
}

.list-services li {
    font-size: 16px;
    padding: 5px 0px;
}

/*==================== ========*/
/*========= Sections =========*/
/*============================*/
#recent-work {
    background: #fff;
}

#about-colored {
    background-color: rgb(255, 221, 0);
    background-attachment: scroll;
    background-position: 50% -1115px;
}

#testimonials {
    background-color: #e4f9ff;

}

#footer-defoult {
    background: #007395;
    color: #8C8989;
}

#page-tree {
    padding-top: 120px;
    padding-bottom: 25px;
    background-color: rgb(255, 221, 0);
    box-shadow: 0 1px 1px rgba(255, 255, 255, 0.5);
    -webkit-box-shadow: 0 1px 1px rgba(255, 255, 255, 0.5);
    -moz-box-shadow: 0 1px 1px rgba(255, 255, 255, 0.5);

}

#hightlight-sec {
    background: url('/images/highlight-bg1.jpg')no-repeat center scroll;

}

#hightlight-sec h1 {
    margin-top: 100px;
    color: #fff;
}

#hightlight-sec-2 {
    background: url('/images/highlight-bg2.jpg')no-repeat center scroll;

}

#hightlight-sec-2 h1 {
    margin-top: 100px;
    color: #fff;
    text-transform: uppercase;
    font-size: 40px;
}

#home-contact {
    background-color: #f7f7f7;
}

/*==================== ========*/
/*========= Portflio =========*/
/*============================*/

#grid .mix {
    display: none;
    opacity: 0;
}

.portfolio-filters {
    margin-bottom: 30px;
    list-style-type: none;
}

.portfolio-filters {
    margin-left: 0px;
    width: 100%;
    padding-left: 0px;
}

.portfolio-filters li {
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
    margin-right: 8px;
    margin-bottom: 5px;
    background-color: #eee;
    color: #222;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;

}

.portfolio-filters li:hover {
    background-color: #F54325;
    color: #fff;
}

.portfolio-filters li.active {
    color: #fff;
    background-color: #F54325;
}

.port-single-desc strong {
    font-weight: 700;
    color: #555;
}

.port-single-desc {
    margin-bottom: 10px !important;
    padding: 10px 20px !important;
}

.port-single-desc p {
    margin: 0px;
}

.port-single-desc a {
    display: block;
}

/*portflio no margin no padding*/
.portfolio-no-margin {
    margin: 0px;
    padding: 0px;
    margin-bottom: 0px !important;
}

/*=========================================================*/
/*========= Portflio and blog image hover overlay =========*/
/*=========================================================*/
.portfolio-item {
    margin-bottom: 20px;
}

.image-sec {
    position: relative;
}

.image-overlay {
    position: absolute;
    text-align: center;
    background: rgba(245, 67, 37, 0.8);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
}

.image-overlay p {
    position: absolute;
    top: 50%;
    color: #fff;
    margin-top: -10px;
    width: 100%;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
}

.image-overlay p i {
    font-size: 44px;
    color: #fff;
}

.image-sec:hover .image-overlay {
    opacity: 1;
}

/*=========================================================*/
/*========= blog =========*/
/*=========================================================*/
.blog-desc {
    padding: 20px;
    background: #fff;
}

.blog-desc h3 {
    font-size: 15px;
    margin-bottom: 10px;
    font-weight: 600;
}

.blog-desc h3 a {
    text-decoration: none;
    color: #333;
}

/*blog pages*/
.blog-items {
    padding-bottom: 20px;
    border-bottom: 0px solid #ddd;
}

.blog-items img {
    width: 100%;
    height: 400px;
}

.blog-items h1 {
    margin: 20px 0px;
    font-size: 25px;
}

.blog-items h1 a {
    margin: 20px 0px;
    color: #444;
}

.blog-post-list {
    margin: 20px 0px;
}

.blog-post-list li {
    color: #444;
}

.blog-post-list li a {
    color: #444;
}

.blog-post-more {
    margin-top: 20px;
}

/*blog grid*/
.blog-grid-box {
    padding: 15px;
    background: #fff;
    box-shadow: 0px 1px 3px #999;
}

.blog-grid-desc {
    padding-top: 15px;
}

.blog-grid-desc h4 {
    margin-bottom: 12px;
}

.blog-grid-desc h4 a {
    color: #555;
}

.blog-grid-meta {
    margin: 13px 0px;
}

.blog-grid-meta span {
    margin-right: 2px;
}

.blog-grid-meta span a {
    font-size: 12px;
    font-weight: 400;
    color: #555;
}

.blog-grid-desc p {
    color: #555;
}

.more-button {
    margin-top: 15px;
}

.blog-grid-btm {
    padding: 5px 0px;
    border-top: 1px solid #eee;
}

.blog-grid-btm h3 {
    margin-bottom: 0px;
}

.blog-grid-btm h3 span a {
    font-size: 14px;
    display: inline-block;
    color: #555;
}

.blog-grid-btm h3 span a i {
    margin-right: 5px;
}

.comment {
    padding: 10px;
    background: #fff;
    box-shadow: 0px 1px 2px #999;
    -webkit-box-shadow: 0px 1px 2px #999;
}

.comment i {
    float: left;
    margin-right: 20px;
    font-size: 24px;
    line-height: 50px;
    width: 50px;
    text-align: center;
    color: #555;
    background: #f7f7f7;
    border-radius: 2px;
    -webkit-border-radius: 2px;
}

.comment h4 {
    margin-bottom: 10px;
}

.comment span {
    font-size: 14px;
}

.comment p {
    overflow: hidden;
}

/*==================== ========*/
/*========= Testimoials =========*/
/*============================*/


#testimonials h1 {
    color: #fff;

}

#testimonials h2.subtitle i {
    color: #fff;
}

#testimonials .testi-text {
    color: #fff;
    font-weight: 400;
    font-size: 23px;
    line-height: 30px;

}

#testimonials .testi-person {
    color: #fff;
    display: block;
    font-weight: 700;
    margin-bottom: 30px;
}

#testimonials .flex-control-nav {
    position: relative;
    bottom: 0;
    margin-top: 20px;
}

/*==================== ========*/
/*========= breadcrumbs overrides =========*/
/*============================*/
#page-tree h1 {
    margin: 0px;
    font-weight: 300;
}

#page-tree .breadcrumb {
    margin-bottom: 0;
    margin-top: 13px;
    padding: 0px 0;
    background: transparent;
}

#page-tree .breadcrumb li a {
    color: #000;
}

#page-tree .breadcrumb li i:before {
    color: #000 !important;
}

#page-tree .breadcrumb li.active a {
    color: #F54325;
}

/*==================== ========*/
/*========= footers =========*/
/*============================*/

/*footers default*/
.footer-column h3 {
    color: #ddd;
    font-size: 20px;
    font-weight: 300;
}

.post-list li a {
    padding: 11px 0px;
    border-bottom: 1px solid #505152;
    display: block;
    color: #8C8989;
    font-size: 12px;
    font-weight: 400;
}

.work-list li {
    display: inline-block;
    margin: 0 10px 10px 0;
}

.work-list li a img {
    width: 53px;
    height: 40px;
    padding: 3px;
    border: 1px solid #505152;
    -webkit-transition: all 200ms ease-in;
    -o-transition: all 200ms ease-in;
    -moz-transition: all 200ms ease-in;
    transition: all 200ms ease-in;
}

.work-list li a img:hover {
    border-color: #F54325;
}

.contact-list li {
    padding-left: 20px;
    position: relative;
    margin-bottom: 10px;
}

.contact-list li i {
    color: #F54325;
    font-size: 15px;
    position: absolute;
    top: 5px;
    left: 0;
}

.contact-list li strong {
    font-size: 14px;
}


/*==================== ========*/
/*========= footer option 2 =========*/
/*============================*/
#footer-option {
    background: none repeat scroll 0% 0% #363839;
    color: #8C8989;
}

#footer-option h3 {
    font-weight: 300;
}

#footer-copyright {
    padding: 20px 0px;
    background-color: #444444;
}

#footer-copyright span {
    color: #8C8989;
}

#footer-option .form-control {
    height: 32px;
}

/*==================== ========*/
/*========= sidebar =========*/
/*============================*/
.sidebarbox-title {
    margin-bottom: 15px;
    font-size: 19px;
    font-weight: 400;
    border-bottom: 1px solid #eee;
    line-height: 30px;
    color: #000;
    text-transform: uppercase;
}

.sidebar-box {
    background-color: #fff;
    color: #666;
    margin-bottom: 30px;
}

.sidebar-box .form-control {
    height: 32px;
}

.cat-list li {
    padding: 5px 0px;
    border-bottom: 1px solid #ddd;
}

.cat-list li a {
    display: block;
    color: #505152;
    font-weight: 400;
    font-size: 14px;
    -webkit-transition: all 200ms ease-in;
    -o-transition: all 200ms ease-in;
    -moz-transition: all 200ms ease-in;
    transition: all 200ms ease-in;
}

.cat-list li a:hover {
    color: #F54325;
}

.sidebar-box .work-list {
    margin-bottom: 0px;
}

.sidebar-box .work-list li {
    margin: 0px;
}

.sidebar-box .work-list li a img {
    width: 60px;
    margin-top: 5px;
}

.taglist a {
    padding: 3px 8px;
    color: #fff;
    background: #555;
    margin: 3px;
    display: inline-block;
    border-radius: 2px;
    -webkit-border-radius: 2px;
    font-size: 14px;
    -webkit-transition: all 200ms ease-in;
    -o-transition: all 200ms ease-in;
    -moz-transition: all 200ms ease-in;
    transition: all 200ms ease-in;
}

.taglist a:hover {
    background: #F54325;
}



/*==================== ========*/
/*========= error page =========*/
/*============================*/
.error-page h2 {
    font-weight: 300;
    font-size: 120px;
}



/*==================== ========*/
/*========= Pricing tabels =========*/
/*============================*/

.price-box {
    padding: 25px 0px;
    background-color: #fff;
    text-align: center;
    overflow: hidden;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    box-shadow: 0px 1px 2px #999;
    -webkit-box-shadow: 0px 1px 2px #999;
}

.price-box h3 {
    font-size: 35px;
}

.price-box h3 small {
    font-size: 13px;
    margin-left: 5px;
}

.pricing-head {
    margin: 0px;
    padding: 20px 0px;
    background: #555;
    color: #fff;
}

.price-desc {
    margin-top: 25px;
    margin-bottom: 25px;
}

.price-desc p {
    padding: 10px 0px;
    display: block;
    border-bottom: 1px solid #eee;
    margin: 0px;
}

.price-box-popular .pricing-head {
    background-color: #F54325;
}


/*==================== ========*/
/*========= team =========*/
/*============================*/
.person-desc {
    padding: 20px 15px;
    background-color: #fff;

}

.person-desc h4 {
    font-weight: 700;
    margin-bottom: 10px;
}

.person-desc h4 small {
    font-size: 12px;
    margin-left: 12px;
}

.person-desc .social {
    margin-bottom: 0px;
    margin-top: 15px;
}





/*==================== ========*/
/*========= Forms =========*/
/*============================*/
.form-box {
    padding: 15px;
    background: #fff;
    box-shadow: 0px 1px 2px #999;
    -webkit-box-shadow: 0px 1px 2px #999;
}

select,
textarea,
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"],
.form-control {
    display: inline-block;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    padding: 0 10px;
    margin-bottom: 15px;
    font-size: 14px;
    line-height: 25px;
    color: #7a7a7a;
    border: 1px solid #dadada;
    resize: none;
    vertical-align: middle;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    background: #FFF;
    -webkit-border-radius: 0;
    border-radius: 0;
    -webkit-transition: all 200ms ease-in;
    -o-transition: all 200ms ease-in;
    -moz-transition: all 200ms ease-in;
}

textarea:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
.form-control:focus {
    border: 1px solid #b3b3b3;
    background: #FFF;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

/*shortcodes typography css*/
.colored-bg {
    width: 100%;
    padding: 15px;
    background-color: #F54325;
}

.lead {
    font-weight: 400;
}

/*****************Password error*********************/

.password-error-message {
    color: #efa351;
    font-weight: 500;
}

/*****************google map*********************/

#map-canvas {
    width: 100%;
    height: 400px;
}